import moment from "moment";
import "moment-timezone";

export default (events) => {
  if (!events) {
    return [];
  }

  return events.filter(({ dateTime }) => {
    return moment(dateTime).isSameOrAfter(moment().tz("Europe/Madrid"));
  });
};
