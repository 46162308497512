import React from "react";
import { Card } from "react-bulma-components";
import CardAsidePropTypes from "./types";
import { cCardAside, wrapper } from "./c-card-aside.module.scss";
import CardTitle from "../elements/card-title";
import CardDescription from "../elements/card-description";

export default function CardAside({
  href,
  title,
  shortDescription,
  description,
  children,
}) {
  return (
    <div className={cCardAside}>
      <div className={wrapper}>
        <CardTitle title={title} href={href} />
        {children}
        <CardDescription
          negative
          shortDescription={shortDescription}
          description={description}
        />
      </div>
    </div>
  );
}

CardAside.propTypes = CardAsidePropTypes;
