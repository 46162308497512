export default ({ person, instrument }) => {
  return {
    "@type": "OrganizationRole",
    member: {
      "@type": "Person",
      name: person.name,
    },
    roleName: instrument?.map(({ name }) => name) || [],
  };
};
