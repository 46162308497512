import React, { Children } from "react";
import { Columns } from "react-bulma-components";
import EditionCoverPropTypes from "./types";
import CardTitle from "../../elements/card-title";
import CardAside from "../../card-aside";
import { cardBands } from "./c-edition-cover.module.scss";

export default function EditionCover({ year, title, bands }) {
  return (
    <Columns>
      <Columns.Column narrow>
        <CardAside href={`/edicions/${year}`} title={year} />
      </Columns.Column>
      <Columns.Column>
        <div className={cardBands}>
          {bands?.map(({ title, slug }) => (
            <CardTitle href={`/grups/${slug}`} title={title} small key={slug} />
          ))}
        </div>
      </Columns.Column>
    </Columns>
  );
}

EditionCover.propTypes = EditionCoverPropTypes;
