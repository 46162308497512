import moment from "moment";
import S from "string";
import isBand from "./is-band";
import jsonldBand from "./jsonld-band";

export default ({ description, place, tickets, dateTime, bands, price }) => {
  const musicBands = bands?.filter((band) => isBand(band));

  return musicBands?.length > 0
    ? {
        "@context": "https://schema.org",
        "@type": "MusicEvent",
        organizer: {
          "@type": "Organization",
          name: "Amics del G.A.T. Cardedeu",
          url: "https://nitsambjazz.com/",
        },
        eventStatus: "https://schema.org/EventScheduled",
        eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
        description: S(
          description?.childMarkdownRemark.html || description || ""
        ).stripTags().s,
        image: "https://nitsambjazz.com/images/logo-squared.jpg",
        location: {
          "@type": "MusicVenue",
          name: place.name,
          address: [place.address, place.city].join(", "),
        },
        offers: tickets
          ? {
              "@type": "Offer",
              url: tickets,
              price: price,
              priceCurrency: "EUR",
              validFrom: "2021-09-01T00:00",
              availability: "https://schema.org/InStock",
            }
          : null,
        startDate: dateTime,
        endDate: moment(dateTime).add(2, "hours"),
        name: musicBands?.map(({ title }) => title).join(", ") || "",
        performer: musicBands?.map((band) => jsonldBand(band)),
      }
    : {};
};
