import React, { Children } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import "../index.scss";
import { Link, animateScroll as scroll } from "react-scroll";

import {
  Section,
  Container,
  Columns,
  Heading,
  Icon,
  Block,
} from "react-bulma-components";

import Layout from "../components/layouts/main-layout/index.jsx";
import Event from "../components/bits/event/index";
import EditionCover from "../components/bits/edition-cover/index";
import { EditionsPropTypes, EventsPropTypes } from "./types";
import Body from "../modules/body";
import SEO from "../components/helpers/seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import filteredEvents from "./../utils/helpers/filtered-events";
import jsonldEvent from "../utils/helpers/jsonld-event";
import { isJazzDeXoc } from "../utils/helpers/url";

const Edicions = ({ edicions }) => {
  return (
    edicions &&
    edicions?.map(({ year, bands, title }) => (
      <EditionCover key={year} year={year} title={title} bands={bands} />
    ))
  );
};

Edicions.propTypes = EditionsPropTypes;

export default function PageTemplate({ data }) {
  const page = data.contentfulPage;
  const logoNavigation = data.logoNavigation;

  const slug = get(page, "slug");
  const description = get(page, "description.childMarkdownRemark.html");
  const heroImage = get(page, "heroImage");
  const events = get(page, "events") || [];
  const edicions = get(data, "allContentfulEdition.nodes");

  const activeEvents = [...events]; //filteredEvents(events);

  return (
    <Layout
      heroImage={heroImage}
      title={page.title}
      description={description}
      logoNavigation={logoNavigation}
      slug={slug}
      background={isJazzDeXoc(slug) ? "brown" : null}
      jsonLdData={activeEvents?.map((event) => jsonldEvent(event)) || []}
    >
      <Section>
        <Container>
          <Columns gap="8">
            <Columns.Column tablet={{ size: events ? 6 : 8 }}>
              <Block className="is-hidden-tablet">
                {activeEvents?.length > 0 && page.eventsTitle && (
                  <Link
                    activeClass="active"
                    to="events"
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ color: "#090a1e" }}
                    className="button is-primary"
                  >
                    <Icon className="is-large">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        color="#090a1e"
                        size="md"
                      />
                    </Icon>
                    <span>{page.eventsTitle}</span>
                  </Link>
                )}
              </Block>

              <Body body={page.body} />
            </Columns.Column>
            {activeEvents?.length > 0 && (
              <>
                <Columns.Column>
                  {page.eventsTitle && (
                    <Heading renderAs="h2" id="events">
                      {page.eventsTitle}
                    </Heading>
                  )}
                  {activeEvents.map((eventData) => (
                    <Event transparent {...eventData} key={eventData.id} />
                  ))}
                </Columns.Column>
              </>
            )}
          </Columns>
          {slug === "edicions" && <Edicions edicions={edicions} />}
        </Container>
      </Section>
    </Layout>
  );
}

export const PageData = graphql`
  fragment PageData on ContentfulPage {
    title
    slug
    body {
      childMarkdownRemark {
        html
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    eventsTitle
    events {
      ...EventData
    }
    heroImage {
      description
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;

export const pageQuery = graphql`
  query PageDetailBySlug($slug: String!) {
    logoNavigation: file(extension: {}, name: { eq: "logo-navigation" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    contentfulPage(slug: { eq: $slug }, heroImage: {}) {
      ...PageData
    }
    allContentfulEdition(sort: { fields: year, order: DESC }) {
      nodes {
        id
        year
        title
        bands {
          slug
          title
        }
      }
    }
  }
`;
