import PropTypes from 'prop-types';



export const EditionsPropTypes = {
  editions: PropTypes.arrayOf(PropTypes.Object)
}

export const EventsPropTypes = {
  events: PropTypes.arrayOf(PropTypes.Object)
}