import React from "react";
import {
  cCardDescription,
  cardShortDescription,
} from "./c-card-description.module.scss";
import CardDescriptionTypes from "./types";

export default function CardDescription({
  shortDescription,
  description,
  negative,
}) {
  return (
    <div className={cCardDescription} data-negative={negative}>
      {shortDescription && (
        <div
          className={cardShortDescription}
          dangerouslySetInnerHTML={{
            __html: shortDescription,
          }}
        ></div>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
      )}
    </div>
  );
}

CardDescription.propTypes = CardDescriptionTypes;
