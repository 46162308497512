import React from "react";
import { Container, Content, Section } from "react-bulma-components";
import PropTypes from "prop-types";

export const BodySection = (props) => {
  return (
    <Section>
      <Container>
        <Body {...props} />
      </Container>
    </Section>
  );
};

export default function Body({ body }) {
  return (
    <Content
      dangerouslySetInnerHTML={{
        __html: body?.childMarkdownRemark.html,
      }}
    />
  );
}

Body.propTypes = {
  body: PropTypes.object,
};
